import { Grid, Tooltip } from "@material-ui/core";
import { SortByAlphaOutlined } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import React, { useEffect, useRef, useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { Claim } from "../../../api/claim/Claim";
import { theme } from "../../../theme/theme";
import { TODAY } from "../../../util/DateUtils";
import { ClaimSummary } from "../../ClaimSummary";
import GridHeader from "../../GridHeader";

const OtherToDoTab = React.memo((props: any) => {
  const limitPerPage = 25;
  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsToShow, setClaimsToShow] = useState(0);
  const [activeSearchTerm, setActiveSearchTerm] = useState("");
  const [claimsSortOrder, setClaimsSortOrder] = useState("asc");

  function toggleClaimsSortOrder(): void {
    setClaimsSortOrder(claimsSortOrder === "desc" ? "asc" : "desc");
  }

  const ref = useRef();

  const fetchData = (replace?: boolean) => {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    props.setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
      method: "POST",
      body: JSON.stringify({
        type: "other",
        disablePersonalization:
          props.personalization === false ? true : undefined,
        userId: !!props.selectedManager ? props.selectedManager : undefined,
        enableLegal: props.enableLegal === true ? true : undefined,
        where: {
          creditorFeedbackRequired: {
            not: true,
          },
          activities: {
            some: {
              claimAction: null,
              activityType: {
                in: [
                  "OutboundLetter",
                  "OutboundMail",
                  "InternalDecision",
                  "Other",
                ],
              },
              activityDate: {
                lte: TODAY,
              },
              isPlanned: true,
            },
          },
          stage: {
            in: ["Precourt", "Court", "Monitoring"],
          },
          ...(activeSearchTerm && {
            creditor: {
              id: activeSearchTerm,
            },
          }),
        },
        take: limitPerPage,
        skip: claimsToShow,
        orderBy: {
          updatedAt: claimsSortOrder,
        },
      }),
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          props.setTotalOther(responseJson.total);

          const sortedClaims = responseJson.results?.sort(
            (a: Claim, b: Claim) =>
              new Date(
                a.activities?.[0]?.activityDate || Date.now()
              ).getTime() -
              new Date(b.activities?.[0]?.activityDate || Date.now()).getTime()
          );
          setClaims(sortedClaims);

          if (replace) {
            setClaims(sortedClaims);
          } else {
            setClaims([...claims, ...sortedClaims]);
          }
          props.setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (props.tabValue === 2 && !claims?.length) {
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue]);

  useEffect(() => {
    if (props.tabValue === 2 && claims.length) {
      fetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.personalization,
    props.selectedManager,
    props.enableLegal,
    claimsSortOrder,
    activeSearchTerm,
    claimsToShow,
  ]);

  return (
    <div
      role="tabpanel"
      className="notranslate"
      hidden={props.tabValue !== 2}
      id={`vertical-tabpanel-${2}`}
    >
      <div ref={ref} style={{ flexDirection: "row" }}>
        <div className="flex-row" id="filter-group">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SimpleForm style={{ maxWidth: 340 }} toolbar={false}>
                <ReferenceInput
                  filterToQuery={(searchText) =>
                    !!searchText && searchText.trim().length > 2
                      ? {
                          businessName: { contains: searchText },
                          roles: { array_contains: "creditor" },
                        }
                      : { roles: { array_contains: "creditor" } }
                  }
                  isRequired
                  allowEmpty
                  emptyText="-"
                  source={"creditor.businessName"}
                  reference={"User"}
                  label={"Creditor"}
                  onChange={(searchTerm) => {
                    setClaimsToShow(0);
                    setActiveSearchTerm(searchTerm);
                  }}
                >
                  <AutocompleteInput
                    suggestionLimit={5}
                    shouldRenderSuggestions={(value) => {
                      return value && value.trim().length > 2;
                    }}
                    optionText={"businessName"}
                  />
                </ReferenceInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={6} className="filter-container">
              <Tooltip title={"Reload"}>
                <RotateLeftIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    marginRight: 10,
                    height: 20,
                  }}
                  onClick={() => fetchData(true)}
                />
              </Tooltip>

              <Tooltip
                title={
                  claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"
                }
              >
                <SortByAlphaOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    marginLeft: 10,
                    height: 20,
                    color:
                      claimsSortOrder === "asc"
                        ? theme.palette.grey[500]
                        : theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    toggleClaimsSortOrder();
                  }}
                >
                  {claimsSortOrder === "desc"
                    ? "Sort ascending"
                    : "Sort descending"}
                </SortByAlphaOutlined>
              </Tooltip>
            </Grid>
          </Grid>
        </div>

        <GridHeader />

        {claims && claims.length > 0
          ? claims.map((claim: Claim) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type="other"
                  key={claim.id}
                  refresh={() => {
                    fetchData(true);
                  }}
                />
              );
            })
          : "-"}

        <Button
          style={{
            marginTop: 10,
            maxWidth: 200,
            marginLeft: 0,
          }}
          onClick={() => {
            setClaimsToShow(claimsToShow + limitPerPage);
          }}
          label="Load more"
        />
      </div>
    </div>
  );
});

export default OtherToDoTab;
