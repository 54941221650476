import { JsonValue } from "type-fest";
import { Activity } from "../activity/Activity";
import { Address } from "../address/Address";
import { Claim } from "../claim/Claim";
import { Integration } from "../integration/Integration";
import { PaymentInformation } from "../paymentInformation/PaymentInformation";
import { PaymentPlan } from "../paymentPlan/PaymentPlan";
import { ServiceCall } from "../serviceCall/ServiceCall";
import { EnumUserBusinessContactRelationType } from "./EnumUserBusinessContactRelationType";
import { EnumUserBusinessType } from "./EnumUserBusinessType";
import { EnumUserType } from "./EnumUserType";

export function getReadableUserType(userType: EnumUserType | string): string {
  switch (userType) {
    case EnumUserType.AdditionalContact:
      return "Kontakt";
    case EnumUserType.Default:
      return "Standard";
    case EnumUserType.Lawyer:
      return "Anwalt";
    case EnumUserType.InsolvencyAdministrator:
      return "Insolvenzverwalter";
    default:
      return "";
  }
}

export type User = {
  activitiesAsManager?: Array<Activity>;
  address?: Address | null;
  analyticalRecipients: JsonValue;
  specificAutoSettlementInPercent: JsonValue;
  autoSettlementExpiry?: Date | null;
  birthday?: Date | null;
  businessName: string | null;
  businessType?: EnumUserBusinessType | null;
  businessContactRelationType?: EnumUserBusinessContactRelationType | null;
  claimsAsAssignee?: Array<Claim>;
  claimsAsCreditor?: Array<Claim>;
  claimsAsDebtor?: Array<Claim>;
  claimsAsManager?: Array<Claim>;
  claimsAsContact?: Array<Claim>;
  comment: string | null;
  contactName: string | null;
  courtOrderMinimumB2C: number | null;
  courtOrderMinimumB2B: number | null;
  createdAt: Date;
  customClauses: string | null;
  disableRegistrationOfficeEnquiry: boolean | null;
  disableCourtLitigation: boolean | null;
  disableInsolvencyFiling: boolean | null;
  disableCancellationFee: boolean | null;
  disableCourtOrder: boolean | null;
  email: string | null;
  emailVerificationStatus?: "VALID" | "INVALID" | "UNKNOWN" | null;
  financialRecipients: JsonValue;
  id: string;
  imageUrl: string | null;
  industry?:
    | "Fulfillment"
    | "Fintech"
    | "Marketplace"
    | "Insurance"
    | "Healthcare"
    | "Software"
    | "Mobility"
    | "Lending"
    | "PaymentServiceProvider"
    | "Banking"
    | "Rental"
    | "ECommerce"
    | "Logistics"
    | "Wholesale"
    | "Construction"
    | null;
  integration?: Integration | null;
  isInsolvent?: boolean | null;
  insolvencyDate?: Date | null;
  isVerified: boolean | null;
  lastLogin: Date | null;
  allowPartnerLawyerInteraction: boolean | null;
  useMonthlyBilling: boolean | null;
  maximumPaymentPlanMonths: number;
  successFeeInPercent?: number | null;
  specificSuccessFeeInPercent: JsonValue;
  maximumAmountB2B?: number | null;
  maximumAmountB2C?: number | null;
  minimumAmountB2B?: number | null;
  minimumAmountB2C?: number | null;
  hasLegalKnowledge?: boolean | null;
  hasLanguageKnowledge?: boolean | null;
  hasActiveCreditRatingSubscription?: boolean | null;
  hasActiveReminderSubscription?: boolean | null;
  hasActiveReminderSubscriptionWithCalling?: boolean | null;
  name: string;
  operationalRecipients: JsonValue;
  paymentInformation?: PaymentInformation | null;
  paymentInformations?: Array<PaymentInformation>;
  manager?: User | null;
  managerId?: string;
  operators?: Array<User>;
  paymentPlans?: Array<PaymentPlan>;
  phone: string | null;
  phoneVerificationStatus?: "VALID" | "INVALID" | "UNKNOWN" | null;
  roles: JsonValue;
  taxDeductionAllowed: boolean;
  isVip: boolean;
  updatedAt: Date;
  username: string;
  userType?:
    | "Default"
    | "Lawyer"
    | "InsolvencyAdministrator"
    | "AdditionalContact";
  vatId: string | null;
  serviceCalls?: Array<ServiceCall>;
  utmSource?: string | null;
};

export interface SpecificSuccessFeeItem {
  key: string;
  value: number;
}
