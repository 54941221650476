import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Button, LoadingIndicator } from "react-admin";
import { EnumPaymentPaymentType } from "../../../api/payment/EnumPaymentPaymentType";
import { theme } from "../../../theme/theme";
import { numberFormatEUR } from "../../../util/CurrencyUtil";

type AdditionalCostCardProps = {
  payments: any;
  paymentDataLoaded: any;
  record: any;
  creditor: any;
  creditorAddress: any;
};

const AdditionalCostCard = ({
  payments,
  paymentDataLoaded,
  record,
  creditor,
  creditorAddress,
}: AdditionalCostCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (paymentDataLoaded) {
      setIsLoading(false);
    }
  }, [paymentDataLoaded]);

  const thirdPartyOutboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorOutboundPosition
  );

  const thirdPartyInboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorInboundPosition
  );

  const thirdPartyInboundPaidPayments = thirdPartyInboundPayments.filter(
    (payment) => payment.isPlanned === false
  );

  let thirdPartyInboundPaidPaymentsTotal = parseFloat(
    Math.abs(
      thirdPartyInboundPaidPayments.reduce(function (a, b) {
        if (
          creditorAddress?.country === "DEU" &&
          creditor.taxDeductionAllowed &&
          !b.isTaxFree
        ) {
          return a + parseFloat((b.amount * 1.19).toFixed(2));
        }
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  const thirdPartyPaymentsTotal = parseFloat(
    (
      parseFloat(
        Math.abs(
          thirdPartyOutboundPayments.reduce(function (a, b) {
            return a + (b.isTaxFree ? b.amount : b.amount / 1.19);
          }, 0)
        )?.toFixed(2)
      ) - thirdPartyInboundPaidPaymentsTotal
    )?.toFixed(2)
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Nebenkosten</Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorOutboundPosition"
          }
          startIcon={<CloudDownloadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[600],
            fontSize: 12,
            marginTop: 10,
            marginBottom: 10,
            minWidth: "100%",
          }}
          size={"medium"}
          label="Eingehende Rechnung"
          variant="contained"
        />
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorInboundPosition"
          }
          startIcon={<CloudUploadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[800],
            fontSize: 12,
            minWidth: "100%",
            marginBottom: 20,
          }}
          size={"medium"}
          label="Ausgehende Rechnung"
          variant="contained"
        />
        {thirdPartyOutboundPayments?.length > 0 && (
          <List
            subheader={
              <Typography variant="body2">Eingehende Rechnungen:</Typography>
            }
          >
            {thirdPartyOutboundPayments.map((payment, index) => (
              <>
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant={"body2"}>
                          {`${payment.reference}`}
                        </Typography>
                        {payment.isPlanned && (
                          <div
                            style={{
                              color: theme.palette.error.main,
                              height: 20,
                            }}
                          >
                            <CheckBoxOutlineBlank
                              style={{ height: 20, verticalAlign: "middle" }}
                            />
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {"Unbezahlt"}
                            </span>
                          </div>
                        )}
                      </div>
                    }
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant={"subtitle2"}>
                          {format(new Date(payment.paymentDate), "dd.MM.yyyy")}
                        </Typography>
                        <Typography variant={"subtitle2"}>
                          {numberFormatEUR.format(Math.abs(payment.amount))}
                        </Typography>
                      </div>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </>
            ))}
          </List>
        )}
        {thirdPartyInboundPayments?.length > 0 && (
          <List
            subheader={
              <Typography variant="body2">Ausgehende Rechnungen:</Typography>
            }
          >
            {thirdPartyInboundPayments.map((payment, index) => {
              let amount = payment.amount;
              if (
                creditorAddress?.country === "DEU" &&
                creditor.taxDeductionAllowed &&
                !payment.isTaxFree
              ) {
                amount = parseFloat((amount * 1.19)?.toFixed(2));
              }

              return (
                <>
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant={"body2"}>
                            {`${payment.reference}`}
                          </Typography>
                          <div
                            style={{
                              color: payment.isPlanned
                                ? theme.palette.error.main
                                : theme.palette.success.main,
                              height: 20,
                            }}
                          >
                            {payment.isPlanned ? (
                              <CheckBoxOutlineBlank
                                style={{ height: 20, verticalAlign: "middle" }}
                              />
                            ) : (
                              <CheckBoxOutlined
                                style={{ height: 20, verticalAlign: "middle" }}
                              />
                            )}
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {payment.isPlanned
                                ? "Unbezahlt"
                                : `Bezahlt ${format(
                                    new Date(payment.updatedAt),
                                    "dd.MM.yyyy"
                                  )}`}
                            </span>
                          </div>
                        </div>
                      }
                      secondary={
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant={"subtitle2"}>
                            {format(
                              new Date(payment.paymentDate),
                              "dd.MM.yyyy"
                            )}
                          </Typography>
                          <Typography variant={"subtitle2"}>
                            {numberFormatEUR.format(Math.abs(amount))}
                          </Typography>
                        </div>
                      }
                    ></ListItemText>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </>
              );
            })}
          </List>
        )}

        {thirdPartyPaymentsTotal > 0 && (
          <Typography
            variant="body2"
            style={{
              textAlign: "right",
              padding: 10,
              color: theme.palette.error.main,
              fontWeight: 600,
            }}
          >
            Offen netto: {numberFormatEUR.format(thirdPartyPaymentsTotal)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AdditionalCostCard;
