import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import { default as PaymentIcon } from "@material-ui/icons/Payment";
import { differenceInDays } from "date-fns";
import React from "react";
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceManyField,
  TextField,
  TopToolbar,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../../../../api/payment/EnumPaymentPaymentType";
import { FINANCE_MANAGERS } from "../../../../config/AppConfig";
import { theme } from "../../../../theme/theme";
import { numberFormatEUR } from "../../../../util/CurrencyUtil";

const PaymentListItems = (props: any) => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { identity } = useGetIdentity();
  const uniquePaymentTypes = new Map<string, string>();

  const numberFormat = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: props.claim?.currency || "EUR",
  });

  const enableDeletion = FINANCE_MANAGERS.includes(
    identity?.username.toString()
  );
  return (
    <>
      <Datagrid
        optimized
        hasBulkActions={enableDeletion}
        selectedIds={listContext.selectedIds}
        rowClick="edit"
        isRowSelectable={() => enableDeletion}
        isRowExpandable={(record) => record?.reference}
        rowStyle={(record) => {
          const values = Array.from(uniquePaymentTypes.values()) as string[];

          if (
            values.includes(record?.id?.toString()) ||
            (!NEGATIVE_PAYMENT_TYPES.includes(record?.paymentType) &&
              !THIRD_PARTY_PAYMENT_TYPES.includes(record?.paymentType) &&
              record?.paymentType !== "ClaimPosition" &&
              record?.paymentType !== "Payout" &&
              !uniquePaymentTypes.has(record?.paymentType))
          ) {
            uniquePaymentTypes.set(record?.paymentType, record?.id?.toString());
            return {
              backgroundColor: "#ebf1ff",
            };
          }
        }}
      >
        <DateField
          source="paymentDate"
          label="Payment Date"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField source="paymentType" label="Type" />
        <FunctionField
          label="Amount"
          render={(record: any) => (
            <span
              style={
                record?.paymentType === "Payout"
                  ? { color: theme.palette.secondary.main, fontWeight: 600 }
                  : record?.paymentType === "ClaimPosition"
                  ? { color: theme.palette.primary.dark, fontWeight: 600 }
                  : record?.amount < 0
                  ? record?.paymentType === "ExistingPayments"
                    ? { color: theme.palette.warning.dark, fontWeight: 600 }
                    : { color: theme.palette.success.dark, fontWeight: 600 }
                  : { color: theme.palette.error.dark, fontWeight: 600 }
              }
            >
              {[
                "ExistingPayments",
                "ClaimPosition",
                "WriteOffDiscount",
                "CreditorInterest",
              ].includes(record.paymentType as EnumPaymentPaymentType)
                ? numberFormat.format(Math.abs(record?.amount) || 0)
                : numberFormatEUR.format(Math.abs(record?.amount) || 0)}
            </span>
          )}
        />
        <TextField label="Reference" source="reference" />
        <FunctionField
          label="Planned"
          source="isPlanned"
          render={(payment: any) => {
            if (payment?.isPlanned) {
              return (
                <icons.CheckCircleRounded
                  style={{
                    fontSize: 20,
                    color: theme.palette.primary.main,
                    verticalAlign: "middle",
                  }}
                />
              );
            }
            return "";
          }}
        />
      </Datagrid>
      {!!listContext.selectedIds && !!listContext.selectedIds.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={async () => {
            for (const selectedId of listContext.selectedIds) {
              await dataProvider.delete("Payment", {
                id: selectedId,
              });
            }
            listContext.onUnselectItems();
            refresh(true);
          }}
          label="Delete"
        />
      )}
    </>
  );
};

const PaymentList = ({
  paymentPlans,
  absoluteTotal,
}: any): React.ReactElement => {
  const claim = useRecordContext();
  const useRates = paymentPlans?.[0]?.id ? true : false;
  let toBePaid = useRates
    ? (absoluteTotal / paymentPlans?.[0]?.numberOfPayments)?.toFixed(2)
    : claim?.totalPending?.toFixed(2);

  const { identity } = useGetIdentity();

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
            <icons.Event style={{ verticalAlign: "sub" }} /> Payment Plan
          </Typography>
          <Button
            variant={claim?.paymentPlans?.length !== 0 ? "contained" : "text"}
            size={"medium"}
            disabled={claim?.paymentPlans?.length !== 0}
            href={
              "/#/PaymentPlan/create?claimId=" +
              claim?.id +
              "&debtorId=" +
              claim?.debtor?.id
            }
            startIcon={<Add />}
            label="Payment Plan"
          />
          {claim?.paymentPlans?.length !== 0 && (
            <>
              <br />
              <br />
              <Typography variant="subtitle2">
                Existing payment plans:
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="buttons-align-end">
            <Typography variant="h6" style={{ marginTop: 20, marginRight: 10 }}>
              <PaymentIcon style={{ verticalAlign: "sub" }} /> Payment Actions
            </Typography>
            <TopToolbar className="align-btns">
              {FINANCE_MANAGERS.includes(identity?.username.toString()) && (
                <Button
                  href={
                    "/#/Payment/create?claimId=" +
                    claim?.id +
                    "&amount=" +
                    (-toBePaid || 0) +
                    "&paymentType=" +
                    (useRates ? "PaymentRate" : "DebtClearance")
                  }
                  startIcon={<Add />}
                  style={{ backgroundColor: theme.palette.success.dark }}
                  label="Incoming"
                />
              )}
              <Button
                href={
                  "/#/Payment/create?claimId=" +
                  claim?.id +
                  "&amount=" +
                  (-claim?.originalAmountDue || 0) +
                  "&paymentType=WriteOffDiscount"
                }
                startIcon={<Add />}
                style={{ backgroundColor: theme.palette.error.dark }}
                label="Write-off"
              />
              <Button
                href={
                  "/#/Payment/create?claimId=" +
                  claim?.id +
                  "&amount=" +
                  (-claim?.originalAmountDue || 0) +
                  "&paymentType=ExistingPayments"
                }
                startIcon={<Add />}
                style={{ backgroundColor: theme.palette.warning.dark }}
                label="Creditor Payment"
              />
              <Button
                href={"/#/Payment/create?claimId=" + claim?.id}
                startIcon={<Add />}
                label="Other"
              />
            </TopToolbar>
          </div>
        </Grid>
      </Grid>
      <ReferenceManyField reference="PaymentPlan" target="ClaimId">
        <Datagrid optimized>
          <DateField
            source="createdAt"
            label="Created At"
            locales="de-DE"
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
          <BooleanField label="Active" source="isActive" />
          <TextField label="Rates" source="numberOfPayments" />
          <FunctionField
            label="Next Rate Due"
            source="nextPaymentDueDate"
            locales="de-DE"
            render={(paymentPlan: any) => {
              if (claim?.totalPending > 0 && paymentPlan?.nextPaymentDueDate) {
                return (
                  <span>
                    {new Date(
                      paymentPlan.nextPaymentDueDate
                    )?.toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                );
              }
              return "-";
            }}
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
          <BooleanField label="Court Title Note" source="hasCourtTitleNote" />

          <DateField
            source="updatedAt"
            label="Updated At"
            locales="de-DE"
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
          <FunctionField
            label="Actions"
            style={{ fontWeight: 600 }}
            render={(record: any) => {
              return (
                <>
                  <Button
                    href={"/#/PaymentPlan/" + record?.id + "/show"}
                    startIcon={<icons.Visibility />}
                    label="Show"
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: theme.palette.success.dark,
                    }}
                  />
                  <Button
                    href={"/#/PaymentPlan/" + record?.id + "/edit"}
                    startIcon={<icons.Edit />}
                    label="Edit"
                    size={"small"}
                    variant="contained"
                    style={{
                      marginLeft: 6,
                      backgroundColor: theme.palette.warning.dark,
                    }}
                  />
                </>
              );
            }}
          />
        </Datagrid>
      </ReferenceManyField>
      <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
        <PaymentIcon style={{ verticalAlign: "sub" }} /> Payments
      </Typography>
      <ReferenceManyField
        addLabel
        reference="Payment"
        target="ClaimId"
        label="Payments"
        sort={{ field: "paymentDate", order: "DESC" }}
        pagination={<Pagination />}
        perPage={500}
      >
        <PaymentListItems claim={claim} />
      </ReferenceManyField>
    </>
  );
};

interface PaymentsTabProps {
  absoluteTotal: number;
}

export const PaymentsTab: React.FC<PaymentsTabProps> = ({ absoluteTotal }) => {
  const record = useRecordContext();
  const dueDateDifference = differenceInDays(
    record?.dueDate,
    record?.invoiceCreatedAt
  );

  return (
    <>
      <Grid container spacing={2} style={{ padding: "30px 0" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={2}
          style={{ width: "20%", minWidth: "20%" }}
        >
          <Card>
            <CardContent className="content-flexCol">
              <Typography variant="body2">Original amount</Typography>
              <NumberField
                label="Original Amount Due (gross)"
                source="originalAmountDue"
                locales={"de-DE"}
                options={{
                  style: "currency",
                  currency: record?.currency || "EUR",
                  minimumFractionDigits: 2,
                }}
                style={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  color: theme.palette.secondary.main,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          style={{ width: "20%", minWidth: "20%" }}
        >
          <Card>
            <CardContent className="content-flexCol">
              <Typography variant="body2">Total Pending</Typography>
              <NumberField
                label="Total Pending"
                source="totalPending"
                locales={"de-DE"}
                options={{
                  style: "currency",
                  currency: record?.currency || "EUR",
                  minimumFractionDigits: 2,
                }}
                style={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  color: theme.palette.secondary.main,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          style={{ width: "20%", minWidth: "20%" }}
        >
          <Card>
            <CardContent className="content-flexCol">
              <Typography variant="body2">Total Paid</Typography>
              <NumberField
                label="Total Paid"
                source="totalPaid"
                defaultValue={record?.totalPaid ?? 0}
                locales={"de-DE"}
                style={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  color:
                    record?.totalPaid === 0
                      ? theme.palette.secondary.main
                      : theme.palette.success.dark,
                }}
                options={{
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          style={{ width: "20%", minWidth: "20%" }}
        >
          <Card>
            <CardContent className="content-flexCol">
              <Typography variant="body2">Invoice Created</Typography>
              <DateField
                label="Invoice Created At"
                source="invoiceCreatedAt"
                locales="de-DE"
                options={{
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "Europe/Berlin",
                }}
                style={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  color: theme.palette.secondary.main,
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          style={{ width: "20%", minWidth: "20%" }}
        >
          <Card>
            <CardContent className="content-flexCol">
              <>
                <Typography variant="body2">Due Date</Typography>
                <div>
                  <DateField
                    label="Due Date"
                    source="dueDate"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                    style={{
                      fontWeight: 600,
                      fontSize: "1rem",
                      color: theme.palette.secondary.main,
                    }}
                  />
                  <Tooltip
                    title={`${
                      dueDateDifference > 0
                        ? `Zahlungsziel: ${dueDateDifference} Tage`
                        : "Zahlungsziel: sofort"
                    }`}
                  >
                    <span
                      style={{
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        color: "#fff",
                        lineHeight: "1rem",
                        display: "inline-block",
                        marginLeft: 5,
                        position: "absolute",
                        right: 0,
                        top: 38,
                        border: "1px solid var(--secondary)",
                        background: "var(--secondary)",
                        borderRadius: 3,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 0,
                        padding: 5,
                      }}
                    >
                      {dueDateDifference > 0
                        ? ` +${dueDateDifference}`
                        : "Sofort"}
                    </span>
                  </Tooltip>
                </div>
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <PaymentList absoluteTotal={absoluteTotal} />
    </>
  );
};
