import {
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  ArrowDownwardOutlined,
  CloseRounded,
  CloudDownloadOutlined,
  FileCopyOutlined,
  FolderOpenOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import {
  Button,
  DataProvider,
  DataProviderProxy,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { defaultStyles, FileIcon } from "react-file-icon";
import { Claim } from "../../../../api/claim/Claim";
import { theme } from "../../../../theme/theme";
import { DATE_REGEX } from "../../../../util/ClaimUtils";
import { parseDate } from "../../../../util/DateUtils";

const FileList = (): React.ReactElement => {
  let record: Claim = useRecordContext();
  let [internalFiles, setInternalFiles] = React.useState<string[]>([]);
  let [externalFiles, setExternalFiles] = React.useState<string[]>([]);
  let [allFiles, setAllFiles] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedMainFiles, setSelectedMainFiles] = React.useState<string[]>(
    []
  );
  const [selectedGeneratedFiles, setSelectedGeneratedFiles] = React.useState<
    string[]
  >([]);

  const refresh = useRefresh();
  const notify = useNotify();

  React.useEffect(() => {
    if (record?.fileUrls) {
      try {
        const urls = JSON.parse(record?.fileUrls as string);

        const matchingUrls = [];
        const nonMatchingUrls = [];

        const conditions = (url: string) =>
          !url.toLowerCase().includes("dunning") &&
          !url.toLowerCase().includes("courtprocessintro") &&
          !url.toLowerCase().includes("monitoring") &&
          !url.toLowerCase().includes("paymentplan");

        urls.forEach((url: string) => {
          if (conditions(url)) {
            nonMatchingUrls.push(url);
          } else {
            matchingUrls.push(url);
          }
        });

        setInternalFiles(matchingUrls);
        setExternalFiles(nonMatchingUrls);
        setAllFiles(urls);
      } catch (e) {
        notify("error");
      }
    }
  }, [record?.fileUrls, notify]);

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: { Authorization: localStorage.getItem("credentials") || "" },
      };

      const files = event.target.files;

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            await fetch(
              process.env.REACT_APP_SERVER_URL +
                "/api/claims/" +
                record?.id +
                "/files",
              {
                method: "POST",
                body: formData,
                ...authHeader,
              }
            );

            await new Promise((resolve) => setTimeout(resolve, 250));
          }

          notify("Uploaded successfully", "success", null, false);
          refresh();
        } catch (error) {
          notify("Upload failed", "error", null, false);
        }
      }
    },
  };

  function handleFileClick(
    record: Claim,
    url: string,
    saveFile?: boolean,
    setIsLoading?: any
  ): void {
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };

    setIsLoading(true);
    const cleanClaimReference = record?.reference?.replaceAll("/", "-");
    let urlToFetch = url.split(record?.reference + "/").pop();
    urlToFetch = encodeURIComponent(urlToFetch || "");

    if (urlToFetch) {
      fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/" +
          cleanClaimReference +
          "/file/" +
          urlToFetch,
        authHeader
      ).then((response) => {
        if (response.ok) {
          let anchor = document.createElement("a");
          document.body.appendChild(anchor);

          response.blob().then((blobby) => {
            const objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = urlToFetch;
            anchor.target = "_blank";
            anchor.click();
            document.body.removeChild(anchor);

            if (!saveFile) {
              window.open(objectUrl, "_blank");
            }

            setTimeout(() => {
              window.URL.revokeObjectURL(objectUrl);
            }, 500);
            notify("File downloaded", "info", null, false);
          });
        }

        setIsLoading(false);
      });
    }
  }

  const useStyles = makeStyles((theme) => ({
    fileInput: {
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed #ccc",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "border-color 0.3s",
      "&:hover": {
        borderColor: "#999",
      },
    },
  }));

  const classes: ClassNameMap<"fileInput"> = useStyles();

  const dataProvider: DataProviderProxy<DataProvider> = useDataProvider();

  const handleSelectFile = (url: string, isMainFile: boolean) => {
    if (isMainFile) {
      setSelectedMainFiles((prev) => {
        if (prev.includes(url)) {
          return prev.filter((file) => file !== url);
        }
        return [...prev, url];
      });
    } else {
      setSelectedGeneratedFiles((prev) => {
        if (prev.includes(url)) {
          return prev.filter((file) => file !== url);
        }
        return [...prev, url];
      });
    }
  };

  const handleSelectAll = (files: string[], isMainFile: boolean) => {
    if (isMainFile) {
      if (selectedMainFiles.length === files.length) {
        setSelectedMainFiles([]);
      } else {
        setSelectedMainFiles(files);
      }
    } else {
      if (selectedGeneratedFiles.length === files.length) {
        setSelectedGeneratedFiles([]);
      } else {
        setSelectedGeneratedFiles(files);
      }
    }
  };

  const renderFiles = (
    files: string[],
    allFiles: string[],
    record: Claim,
    notify: any,
    dataProvider: DataProvider,
    handleFileClick: any,
    setIsLoading: any,
    isMainFile: boolean
  ) =>
    files.map((url: string) => {
      let fileTitle = url
        .replace("media/", "")
        .replace(record?.reference + "/", "")
        .replace(record?.reference, "");

      const splitFileTitle = fileTitle.split(".");
      let fileDate;
      const fileExtension = splitFileTitle.pop();
      fileTitle = splitFileTitle.shift();

      const dateMatch = fileTitle?.match(DATE_REGEX);
      if (dateMatch) {
        fileDate = parseDate(dateMatch[1]);
        fileTitle = fileTitle
          .replace(DATE_REGEX, "")
          .replace(/^-/, "")
          .replace("--", "-")
          .replace(/^-/, "")
          .replace("--", "-")
          .replace(/^[\w]{2}-/, "")
          .replace(/-[\w]{2}$/, "")
          .trim()
          .replace(/ -$/, "")
          .replace(/-$/, "");
      }

      return (
        <ListItem key={url} style={{ paddingBottom: 5, paddingTop: 5 }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={
                isMainFile
                  ? selectedMainFiles.includes(url)
                  : selectedGeneratedFiles.includes(url)
              }
              onChange={() => handleSelectFile(url, isMainFile)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemIcon style={{ height: 30, width: 30 }}>
            <FileIcon
              labelUppercase
              color={
                !url.toLowerCase().includes("dunning") &&
                !url.toLowerCase().includes("courtprocessintro") &&
                !url.toLowerCase().includes("monitoring") &&
                !url.toLowerCase().includes("paymentplan")
                  ? theme.palette.grey[300]
                  : theme.palette.secondary.main
              }
              extension={fileExtension}
              {...defaultStyles[fileExtension]}
            />
          </ListItemIcon>
          <ListItemText
            id={url}
            primary={fileTitle || url}
            style={{
              width: "50%",
              padding: 8,
              cursor: "pointer",
            }}
            className="click-indicator"
            onClick={() => handleFileClick(record, url, false, setIsLoading)}
          />
          <ListItemText
            style={{
              marginLeft: 10,
              color: theme.palette.grey[500],
            }}
          >
            {`${fileDate && format(fileDate, "dd.MM.yyyy")}`}
          </ListItemText>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Download File">
              <CloudDownloadOutlined
                onClick={() =>
                  handleFileClick(record, url, false, setIsLoading)
                }
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Copy path">
              <FolderOpenOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(url);
                  notify("Copied to clipboard", "info", null, false);
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon style={{ cursor: "pointer" }} className="mobile-hide">
            <Tooltip title="Copy file name">
              <FileCopyOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(fileTitle);
                  notify("Copied to clipboard", "info", null, false);
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemSecondaryAction
            className="mobile-hide"
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm("Do you really want to remove this file?")) {
                dataProvider.update("Claim", {
                  id: record.id,
                  data: {
                    fileUrls: JSON.stringify(
                      allFiles.filter((parsedUrl) => parsedUrl !== url)
                    ),
                  },
                  previousData: record,
                });

                notify(`Successfully Deleted ${record.id} File`);
              }
            }}
          >
            <IconButton>
              <CloseRounded
                style={{
                  color: theme.palette.error.main,
                }}
              />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

  return (
    <Grid
      container
      style={{ alignItems: "center", justifyContent: "space-between" }}
      className="clearListDot"
    >
      <Grid xs={12}>
        <Typography
          variant="h5"
          style={{
            marginTop: 20,
          }}
        >
          Files {isLoading && <CircularProgress size={24} />}
        </Typography>
      </Grid>

      <Grid xs={12} lg={7}>
        <label htmlFor="file" className={classes.fileInput}>
          <Input
            id="file"
            name="file"
            type="file"
            style={{ width: "100%", padding: 25 }}
            inputProps={{ multiple: true }}
            {...uploadProps}
          />
        </label>
      </Grid>
      <Grid xs={12} lg={5}>
        <div className="download-btns">
          <Button
            variant={"contained"}
            size={"medium"}
            color={"default"}
            disabled={!allFiles && allFiles.length > 0}
            style={{ marginBottom: 10, width: "100%" }}
            startIcon={<ArrowDownwardOutlined />}
            onClick={() => {
              if (allFiles.length > 0) {
                allFiles.forEach((url) =>
                  handleFileClick(record, url, true, setIsLoading)
                );
              }
            }}
          >
            <span style={{ fontSize: "0.8125rem" }}>{"Download all"}</span>
          </Button>
          <div className="download-btns --multiple">
            <Button
              disabled={
                !externalFiles?.length && selectedMainFiles.length === 0
              }
              variant="text"
              size={"small"}
              startIcon={<ArrowDownwardOutlined />}
              onClick={() => {
                if (selectedMainFiles.length > 0) {
                  selectedMainFiles.forEach((url) =>
                    handleFileClick(record, url, true, setIsLoading)
                  );
                } else {
                  externalFiles.forEach((url) =>
                    handleFileClick(record, url, true, setIsLoading)
                  );
                }
              }}
              style={{ minHeight: "100%" }}
            >
              <span style={{ fontSize: "0.8125rem" }}>
                {selectedMainFiles.length > 0
                  ? `Selected main (${selectedMainFiles.length})`
                  : "All main"}
              </span>
            </Button>
            <Button
              variant="text"
              size={"small"}
              disabled={
                !internalFiles?.length && selectedGeneratedFiles.length === 0
              }
              style={{ backgroundColor: "var(--secondary)" }}
              startIcon={<ArrowDownwardOutlined />}
              onClick={() => {
                if (selectedGeneratedFiles.length > 0) {
                  selectedGeneratedFiles.forEach((url) =>
                    handleFileClick(record, url, true, setIsLoading)
                  );
                } else {
                  internalFiles.forEach((url) =>
                    handleFileClick(record, url, true, setIsLoading)
                  );
                }
              }}
            >
              <span style={{ fontSize: "0.8125rem" }}>
                {selectedGeneratedFiles.length > 0
                  ? `Selected generated (${selectedGeneratedFiles.length})`
                  : "All generated"}
              </span>
            </Button>
          </div>
        </div>
      </Grid>

      <Grid xs={12} style={{ marginTop: 20 }}>
        {externalFiles && externalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Main Files
            </Typography>
            <List
              style={{
                width: "100%",
                backgroundColor: "background.paper",
                maxWidth: "100%",
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedMainFiles.length === externalFiles.length}
                    indeterminate={
                      selectedMainFiles.length > 0 &&
                      selectedMainFiles.length < externalFiles.length
                    }
                    onChange={() => handleSelectAll(externalFiles, true)}
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </ListItem>
              {renderFiles(
                externalFiles,
                allFiles,
                record,
                notify,
                dataProvider,
                handleFileClick,
                setIsLoading,
                true
              )}
            </List>
          </>
        )}

        <Divider style={{ margin: "1rem 0" }} />

        {internalFiles && internalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Generated Files
            </Typography>
            <ListItem>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    selectedGeneratedFiles.length === internalFiles.length
                  }
                  indeterminate={
                    selectedGeneratedFiles.length > 0 &&
                    selectedGeneratedFiles.length < internalFiles.length
                  }
                  onChange={() => handleSelectAll(internalFiles, false)}
                />
              </ListItemIcon>
              <ListItemText primary="Select All Generated Files" />
            </ListItem>

            {renderFiles(
              internalFiles,
              allFiles,
              record,
              notify,
              dataProvider,
              handleFileClick,
              setIsLoading,
              false
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export const FilesTab: React.FC = () => {
  return <FileList />;
};
