import { Title } from "react-admin";
import { ExpiringClaims } from "../Components/cards/ExpiringClaimsCard";

export const ExpiringClaimsOverview = () => {
  return (
    <>
      <Title title="Expiring Claims" />
      <ExpiringClaims />
    </>
  );
};
