import {
  AppBar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  AssignmentIndOutlined,
  Brightness1Outlined,
  TodayOutlined,
  TrafficOutlined,
} from "@material-ui/icons";
import Email from "@material-ui/icons/Email";
import MarkunreadMailboxOutlined from "@material-ui/icons/MarkunreadMailboxOutlined";
import Phone from "@material-ui/icons/Phone";
import jsonExport from "jsonexport/dist";
import { groupBy, keyBy, mapValues, mergeWith } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Datagrid,
  ListContextProvider,
  RichTextField,
  SelectInput,
  SimpleForm,
  Title,
  downloadCSV,
  useGetList,
  useRefresh,
} from "react-admin";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { ActivityDateField } from "../Components/fields/ActivityDateField";
import { ActivitySummaryField } from "../Components/fields/ActivitySummaryField";
import { Activity } from "../api/activity/Activity";
import { Claim } from "../api/claim/Claim";
import { useManagers } from "../context/hook/useManagers";
import { UserEdit } from "../user/UserEdit";
import {
  getDaysInMonth,
  medianActivityDateDifferenceInMinutes,
} from "../util/DateUtils";

const numberFormat = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const getHoursInTimezone = (date: Date) => {
  return new Date(
    date.toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getHours();
};

const today = new Date();
today.setHours(23);
today.setMinutes(59);
const availableMonths = [
  {
    label: "January",
    value: 0,
  },
  {
    label: "February",
    value: 1,
  },
  {
    label: "March",
    value: 2,
  },
  {
    label: "April",
    value: 3,
  },
  {
    label: "May",
    value: 4,
  },
  {
    label: "June",
    value: 5,
  },
  {
    label: "July",
    value: 6,
  },
  {
    label: "August",
    value: 7,
  },
  {
    label: "September",
    value: 8,
  },
  {
    label: "October",
    value: 9,
  },
  {
    label: "November",
    value: 10,
  },
  {
    label: "December",
    value: 11,
  },
];

const OperationsOverview = () => {
  const [currentManager, setCurrentManager] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState<"day" | "month">("day");
  const refresh = useRefresh();
  const [tabValue, setTabValue] = useState(0);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  );
  const [currentDate, setCurrentDate] = useState<number>(new Date().getDate());
  const actualCurrentYear: number = new Date().getFullYear();
  const [openUsers, setOpenUsers] = useState([
    "total",
    "total-b2c-500",
    "total-b2c-1000",
    "total-b2c-1000+",
    "total-b2b-500",
    "total-b2b-1000",
    "total-b2b-1000+",
  ]);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [totalWaitingClaims, setTotalWaitingClaims] = useState([]);
  const [assignedWaitingClaims, setAssignedWaitingClaims] = useState([]);

  const [reloadMetrics, setReloadMetrics] = useState(true);

  const onSaveUser = (userId: string) => {
    const newOpenUsers = [...openUsers, userId];
    setOpenUsers(newOpenUsers);
    refresh();
    fetchUserTotals();
  };

  const minimumDate = useMemo(() => {
    return new Date(
      currentYear,
      currentMonth,
      mode === "day" ? currentDate + (currentDate > 0 ? 0 : 1) : 1,
      0,
      0
    );
  }, [currentYear, currentMonth, mode, currentDate]);
  const maximumDate = useMemo(() => {
    return new Date(
      currentYear,
      (mode === "day" ? -1 + currentMonth : currentMonth || 0) + 1,
      mode === "day" ? currentDate || 0 : 0,
      23,
      59
    );
  }, [currentYear, currentMonth, mode, currentDate]);

  const [barVisibility, setBarVisibility] = useState({
    mailManualAmount: true,
    phoneCallsAmount: true,
    phoneCallsNotReachedAmount: true,
    plannedPhoneCallsAmount: true,
    otherAmount: true,
    plannedOtherAmount: true,
    researchsAmount: true,
    internalFeedbackAmount: true,
    creditorInformation: true,
    claimAuditsAmount: true,
  });

  const { managers } = useManagers();
  useEffect(() => {
    if (managers && Object.entries(managers).length > 0) {
      setLoadedUsers(
        Object.entries(managers).map(([_key, user]) => ({
          id: user.id,
          contactName: user.contactName || "-",
        }))
      );
    }
  }, [managers]);

  useEffect(() => {
    setReloadMetrics(true);
  }, [currentManager, mode]);

  const toggleChartItem = (data: Payload) => {
    setBarVisibility((prevVisibility) => ({
      ...prevVisibility,
      [data.dataKey as string]: !prevVisibility[data.dataKey as string],
    }));
  };

  const { total: activeClaims, loaded: activeClaimsLoaded } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1 },
    { field: "updatedAt", order: "ASC" },
    {
      status: "EncashmentLevel",
      activities: [
        {
          some: {
            claimAction: "SecondDunning",
          },
          none: {
            claimAction: "FifthDunning",
          },
        },
      ],
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: activeClaimsPartlyPaid,
    loaded: activeClaimsPartlyPaidLoaded,
  } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1 },
    { field: "updatedAt", order: "ASC" },
    {
      payments: [
        {
          some: {
            paymentType: "PaymentRate",
            paymentDate: {
              gte: minimumDate,
              lte: maximumDate,
            },
          },
        },
      ],
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: activeClaimsCompletelyPaid,
    loaded: activeClaimsCompletelyPaidLoaded,
  } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1000 },
    { field: "updatedAt", order: "ASC" },
    {
      updatedAt: {
        gte: minimumDate,
        lte: maximumDate,
      },
      status: "Paid",
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: activeClaimsCompletelyPaidByManager,
    loaded: activeClaimsCompletelyPaidByManagerLoaded,
  } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1000 },
    { field: "updatedAt", order: "ASC" },
    {
      updatedAt: {
        gte: minimumDate,
        lte: maximumDate,
      },
      ...(currentManager && {
        activities: [
          {
            some: {
              manager: {
                id: currentManager,
              },
            },
          },
        ],
      }),
      status: "Paid",
    },
    {
      enabled: reloadMetrics,
    }
  );

  const { total: dueClaimsTotal, loaded: dueClaimsLoaded } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1000 },
    { field: "updatedAt", order: "ASC" },
    {
      status: "Paused",
      creditorFeedbackRequired: {
        not: true,
      },
      activities: [
        {
          some: {},
        },
      ],
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: claimsWaitingForCreditorTotal,
    loaded: claimsWaitingForCreditorLoaded,
  } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1000 },
    { field: "updatedAt", order: "ASC" },
    {
      creditorFeedbackRequired: { equals: true },
    },
    {
      enabled: reloadMetrics,
    }
  );

  const { total: mailsTotal, loaded: mailsLoaded } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundMail",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const { total: activitiesTotal, loaded: activitiesLoaded } =
    useGetList<Activity>(
      "Activity",
      { page: 1, perPage: 1 },
      { field: "activityDate", order: "ASC" },
      {
        activityDate: {
          gte: minimumDate,
          lte: maximumDate,
        },
        isPlanned: {
          equals: false,
        },
        ...(currentManager && { manager: { id: currentManager } }),
      },
      {
        enabled: reloadMetrics,
      }
    );

  const {
    total: mailsManualTotal,
    data: mailsManual,
    loaded: mailsManualLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      claimAction: null,
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundMail",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: claimAudits,
    total: claimAuditsTotal,
    loaded: claimAuditsLoaded,
  } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1000 },
    { field: "updatedAt", order: "ASC" },
    {
      id: {
        equals: "audit",
      },
      createdAt: {
        gte: minimumDate,
        lte: maximumDate,
      },
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: others,
    total: othersTotal,
    loaded: othersLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "Other",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: researchs,
    total: researchsTotal,
    loaded: researchsLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "Research",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: internalFeedback,
    total: internalFeedbackTotal,
    loaded: internalFeedbackLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "InternalFeedback",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: creditorInformation,
    total: creditorInformationTotal,
    loaded: creditorInformationLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "CreditorInformation",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: plannedOthers,
    total: plannedOthersTotal,
    loaded: plannedOthersLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "createdAt", order: "ASC" },
    {
      isPlanned: {
        equals: true,
      },
      createdAt: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "Other",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const { total: responsesTotal, loaded: responsesLoaded } =
    useGetList<Activity>(
      "Activity",
      { page: 1, perPage: 1000 },
      { field: "activityDate", order: "ASC" },
      {
        isPlanned: {
          equals: false,
        },
        result: null,
        activityDate: {
          gte: minimumDate,
          lte: maximumDate,
        },
        activityType: "Response",
      },
      {
        enabled: reloadMetrics,
      }
    );

  const { total: lettersTotal, loaded: lettersLoaded } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundLetter",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    data: phoneCallsPlannedData,
    total: phoneCallsPlannedTotal,
    loaded: phoneCallsPlannedLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "createdAt", order: "ASC" },
    {
      isPlanned: {
        equals: true,
      },
      createdAt: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundCall",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: phoneCallsTotal,
    data: phoneCalls,
    loaded: phoneCallsLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundCall",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  const {
    total: phoneCallsNotReachedTotal,
    data: phoneCallsNotReached,
    loaded: phoneCallsNotReachedLoaded,
  } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 1000 },
    { field: "activityDate", order: "ASC" },
    {
      isPlanned: {
        equals: false,
      },
      result: "NotReached",
      activityDate: {
        gte: minimumDate,
        lte: maximumDate,
      },
      activityType: "OutboundCall",
      ...(currentManager && { manager: { id: currentManager } }),
    },
    {
      enabled: reloadMetrics,
    }
  );

  useEffect(() => {
    if (
      activeClaimsCompletelyPaidLoaded &&
      othersLoaded &&
      activeClaimsCompletelyPaidByManagerLoaded &&
      activeClaimsPartlyPaidLoaded &&
      activeClaimsLoaded &&
      claimsWaitingForCreditorLoaded &&
      mailsLoaded &&
      dueClaimsLoaded &&
      mailsManualLoaded &&
      activitiesLoaded &&
      claimAuditsLoaded &&
      internalFeedbackLoaded &&
      creditorInformationLoaded &&
      responsesLoaded &&
      researchsLoaded &&
      plannedOthersLoaded &&
      lettersLoaded &&
      phoneCallsLoaded &&
      phoneCallsNotReachedLoaded &&
      phoneCallsPlannedLoaded
    ) {
      setReloadMetrics(false);
    }
  }, [
    activeClaimsCompletelyPaidByManagerLoaded,
    activeClaimsCompletelyPaidLoaded,
    activeClaimsLoaded,
    activeClaimsPartlyPaidLoaded,
    activitiesLoaded,
    claimAuditsLoaded,
    claimsWaitingForCreditorLoaded,
    creditorInformationLoaded,
    dueClaimsLoaded,
    internalFeedbackLoaded,
    lettersLoaded,
    mailsLoaded,
    mailsManualLoaded,
    othersLoaded,
    phoneCallsLoaded,
    phoneCallsNotReachedLoaded,
    phoneCallsPlannedLoaded,
    plannedOthersLoaded,
    researchsLoaded,
    responsesLoaded,
    managers,
  ]);

  const groupedMailData = groupBy(mailsManual, (item) => {
    const activityDate = new Date(item.activityDate);
    return mode === "day"
      ? getHoursInTimezone(activityDate)
      : activityDate.getUTCDate();
  });
  const groupedPhoneCallData = groupBy(phoneCalls, (item) => {
    const callDate = new Date(item.activityDate);
    return mode === "day" ? getHoursInTimezone(callDate) : callDate.getDate();
  });

  const groupedPhoneCallNotReachedData = groupBy(
    phoneCallsNotReached,
    (item) => {
      const callDate = new Date(item.activityDate);
      return mode === "day" ? getHoursInTimezone(callDate) : callDate.getDate();
    }
  );

  const groupedPlannedPhoneCallsData = groupBy(
    phoneCallsPlannedData,
    (item) => {
      const createdAt = new Date(item.createdAt);
      return mode === "day"
        ? getHoursInTimezone(createdAt)
        : createdAt.getDate();
    }
  );

  const groupedOthersData = groupBy(others, (item) => {
    const otherDate = new Date(item.activityDate);
    return mode === "day" ? getHoursInTimezone(otherDate) : otherDate.getDate();
  });
  const groupedPlannedOthersData = groupBy(plannedOthers, (item) => {
    const otherDate = new Date(item.createdAt);
    return mode === "day" ? getHoursInTimezone(otherDate) : otherDate.getDate();
  });

  const groupedResearchsData = groupBy(researchs, (item) => {
    const researchDate = new Date(item.activityDate);
    return mode === "day"
      ? getHoursInTimezone(researchDate)
      : researchDate.getDate();
  });

  const groupedInternalFeedbackData = groupBy(internalFeedback, (item) => {
    const otherDate = new Date(item.activityDate);
    return mode === "day" ? getHoursInTimezone(otherDate) : otherDate.getDate();
  });
  const groupedCreditorInformationData = groupBy(
    creditorInformation,
    (item) => {
      const otherDate = new Date(item.activityDate);
      return mode === "day"
        ? getHoursInTimezone(otherDate)
        : otherDate.getDate();
    }
  );

  const groupedClaimAuditsData = groupBy(claimAudits, (item) => {
    const claimDate = new Date(item.updatedAt);
    return mode === "day" ? getHoursInTimezone(claimDate) : claimDate.getDate();
  });

  const sumMailByGroup = mapValues(groupedMailData, (group) => group.length);
  const sumPhoneCallsByGroup = mapValues(
    groupedPhoneCallData,
    (group) => group.length
  );
  const sumPhoneCallsNotReachedByGroup = mapValues(
    groupedPhoneCallNotReachedData,
    (group) => group.length
  );
  const sumPlannedPhoneCallsByGroup = mapValues(
    groupedPlannedPhoneCallsData,
    (group) => group.length
  );
  const sumOthersByGroup = mapValues(
    groupedOthersData,
    (group) => group.length
  );
  const sumPlannedOthersByGroup = mapValues(
    groupedPlannedOthersData,
    (group) => group.length
  );
  const sumResearchsByGroup = mapValues(
    groupedResearchsData,
    (group) => group.length
  );
  const sumInternalFeedbackByGroup = mapValues(
    groupedInternalFeedbackData,
    (group) => group.length
  );
  const sumCreditorInformationByGroup = mapValues(
    groupedCreditorInformationData,
    (group) => group.length
  );
  const sumClaimAuditsByGroup = mapValues(
    groupedClaimAuditsData,
    (group) => group.length
  );

  const mergedData = mergeWith(
    {},
    sumMailByGroup,
    sumPhoneCallsByGroup,
    sumPhoneCallsNotReachedByGroup,
    sumOthersByGroup,
    sumResearchsByGroup,
    sumPlannedOthersByGroup,
    sumPlannedPhoneCallsByGroup,
    sumInternalFeedbackByGroup,
    sumCreditorInformationByGroup,
    sumClaimAuditsByGroup,
    (objValue, srcValue) => (objValue || 0) + (srcValue || 0)
  );

  const combinedData = Object.keys(mergedData).map((group) => ({
    name:
      group +
      (mode === "day" ? ":00" : "." + (maximumDate.getMonth() + 1) + "."),
    mailManualAmount: sumMailByGroup[group] || 0,
    phoneCallsAmount: sumPhoneCallsByGroup[group] || 0,
    phoneCallsNotReachedAmount: sumPhoneCallsNotReachedByGroup[group] || 0,
    plannedPhoneCallsAmount: sumPlannedPhoneCallsByGroup[group] || 0,
    otherAmount: sumOthersByGroup[group] || 0,
    plannedOtherAmount: sumPlannedOthersByGroup[group] || 0,
    researchsAmount: sumResearchsByGroup[group] || 0,
    internalFeedbackAmount: sumInternalFeedbackByGroup[group] || 0,
    creditorInformationAmount: sumCreditorInformationByGroup[group] || 0,
    claimAuditsAmount: sumClaimAuditsByGroup[group] || 0,
  }));

  const [userTotals, setUserTotals] = useState([]); // initialize as an empty array

  const fetchUserTotals = async () => {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    const newTotals = [];
    const users = [...loadedUsers];
    users.push({ id: "total", contactName: "Total" });
    users.push({ id: "total-b2c-500", contactName: "B2C 0-500" });
    users.push({ id: "total-b2c-1000", contactName: "B2C 500-1000" });
    users.push({ id: "total-b2c-1000+", contactName: "B2C 1000+" });
    users.push({ id: "total-b2b-500", contactName: "B2B 0-500" });
    users.push({ id: "total-b2b-1000", contactName: "B2B 500-1000" });
    users.push({ id: "total-b2b-1000+", contactName: "B2B 1000+" });

    let assignedClaims = [];
    for (const user of users) {
      if (
        openUsers.length > 0 &&
        userTotals.length > 0 &&
        user.id.startsWith("total")
      ) {
        const previousTotal = userTotals.find(
          (userTotal) => userTotal.user?.id === user.id
        );
        newTotals.push({
          user: { id: user.id, contactName: user.contactName },
          totalCalls: previousTotal.totalCalls,
          totalWaiting: previousTotal.totalWaiting,
          totalWaitingCreditor: previousTotal.totalWaitingCreditor,
          totalWaitingDebtor: previousTotal.totalWaitingDebtor,
        });
        continue; // unchanged, no need to refetch
      }

      setIsLoading(true);
      const result = {
        calls: 0,
        waitingforus: 0,
        waitingforusCreditor: 0,
        waitingforusDebtor: 0,
        callsVip: 0,
        waitingforusVip: 0,
        waitingforusCreditorVip: 0,
        waitingforusDebtorVip: 0,
      };

      try {
        await fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
          method: "POST",
          body: JSON.stringify({
            type: "calls",
            userId: user.id,
            enableLegal: true,
            isOverview: true,
            disablePersonalization: user.id.startsWith("total") ? true : false,
            where: {
              creditorFeedbackRequired: {
                not: true,
              },
              stage: {
                in: ["Reminder", "Precourt", "Court", "Monitoring"],
              },
              status: { not: "Open" },
              ...(user.id.startsWith("total-b2b") && {
                debtor: {
                  businessType: {
                    not: "Consumer",
                  },
                },
              }),
              ...(user.id.startsWith("total-b2c") && {
                debtor: {
                  businessType: {
                    equals: "Consumer",
                  },
                },
              }),
              ...(user.id.endsWith("1000") && {
                originalAmountDue: {
                  gte: 500,
                  lte: 1000,
                },
              }),
              ...(user.id.endsWith("500") && {
                originalAmountDue: {
                  lte: 500,
                },
              }),
              ...(user.id.endsWith("1000+") && {
                originalAmountDue: {
                  gte: 1000,
                },
              }),
              activities: {
                some: {
                  activityDate: {
                    lte: maximumDate,
                  },
                  activityType: "OutboundCall",
                  isPlanned: true,
                },
              },
            },
          }),
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              result.calls = responseJson.total;
              result.callsVip = responseJson.results.filter(
                (result) => result?.creditor?.isVip
              ).length;
            }
          });

        await fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
          method: "POST",
          body: JSON.stringify({
            type: "claims",
            userId: user.id,
            enableLegal: true,
            isOverview: true,
            disablePersonalization: user.id.startsWith("total") ? true : false,
            where: {
              creditorFeedbackRequired: {
                not: true,
              },
              stage: {
                in: ["Precourt", "Court", "Monitoring"],
              },
              status: "Paused",
              ...(user.id.startsWith("total-b2b") && {
                debtor: {
                  businessType: {
                    not: "Consumer",
                  },
                },
              }),
              ...(user.id.startsWith("total-b2c") && {
                debtor: {
                  businessType: {
                    equals: "Consumer",
                  },
                },
              }),
              ...(user.id.endsWith("1000") && {
                originalAmountDue: {
                  gte: 500,
                  lte: 1000,
                },
              }),
              ...(user.id.endsWith("500") && {
                originalAmountDue: {
                  lte: 500,
                },
              }),
              ...(user.id.endsWith("1000+") && {
                originalAmountDue: {
                  gte: 1000,
                },
              }),
              activities: {
                some: {
                  claimAction: {
                    not: null,
                  },
                },
              },
            },
          }),
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              result.waitingforus = responseJson.total;
              if (user.id === "total") {
                setTotalWaitingClaims(responseJson.results);
              } else if (!user.id.startsWith("total")) {
                responseJson.results.forEach((item) => {
                  if (
                    item.id !== undefined &&
                    !assignedClaims.some(
                      (existingItem) => existingItem.id === item.id
                    )
                  ) {
                    assignedClaims.push(item);
                  }
                });
              }
              result.waitingforusVip = responseJson.results.filter(
                (result) => result?.creditor?.isVip
              ).length;
            }
          });

        await fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
          method: "POST",
          body: JSON.stringify({
            type: "claimsCreditor",
            userId: user.id,
            enableLegal: true,
            isOverview: true,
            disablePersonalization: user.id.startsWith("total") ? true : false,
            where: {
              creditorFeedbackRequired: {
                not: true,
              },
              stage: {
                in: ["Precourt", "Court", "Monitoring"],
              },
              status: "Paused",
              ...(user.id.startsWith("total-b2b") && {
                debtor: {
                  businessType: {
                    not: "Consumer",
                  },
                },
              }),
              ...(user.id.startsWith("total-b2c") && {
                debtor: {
                  businessType: {
                    equals: "Consumer",
                  },
                },
              }),
              ...(user.id.endsWith("1000") && {
                originalAmountDue: {
                  gte: 500,
                  lte: 1000,
                },
              }),
              ...(user.id.endsWith("500") && {
                originalAmountDue: {
                  lte: 500,
                },
              }),
              ...(user.id.endsWith("1000+") && {
                originalAmountDue: {
                  gte: 1000,
                },
              }),
              activities: {
                some: {
                  activityType: {
                    in: ["CreditorFeedback", "CreditorDecision"],
                  },
                  claimAction: null,
                  isPlanned: false,
                },
              },
            },
          }),
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              result.waitingforusCreditor = responseJson.total;
              result.waitingforusCreditorVip = responseJson.results.filter(
                (result) => result?.creditor?.isVip
              ).length;
            }
          });

        await fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/internal", {
          method: "POST",
          body: JSON.stringify({
            type: "claimsDebtor",
            userId: user.id,
            enableLegal: true,
            isOverview: true,
            disablePersonalization: user.id.startsWith("total") ? true : false,
            where: {
              creditorFeedbackRequired: {
                not: true,
              },
              stage: {
                in: ["Precourt", "Court", "Monitoring"],
              },
              status: "Paused",
              ...(user.id.startsWith("total-b2b") && {
                debtor: {
                  businessType: {
                    not: "Consumer",
                  },
                },
              }),
              ...(user.id.startsWith("total-b2c") && {
                debtor: {
                  businessType: {
                    equals: "Consumer",
                  },
                },
              }),
              ...(user.id.endsWith("1000") && {
                originalAmountDue: {
                  gte: 500,
                  lte: 1000,
                },
              }),
              ...(user.id.endsWith("500") && {
                originalAmountDue: {
                  lte: 500,
                },
              }),
              ...(user.id.endsWith("1000+") && {
                originalAmountDue: {
                  gte: 1000,
                },
              }),
              activities: {
                some: {
                  activityType: {
                    in: ["Response"],
                  },
                  claimAction: null,
                  isPlanned: false,
                },
              },
            },
          }),
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              result.waitingforusDebtor = responseJson.total;
              result.waitingforusDebtorVip = responseJson.results.filter(
                (result) => result?.creditor?.isVip
              ).length;
            }
          });

        newTotals.push({
          user: { id: user.id, contactName: user.contactName },
          totalCalls: result.calls,
          totalWaiting: result.waitingforus,
          totalWaitingCreditor: result.waitingforusCreditor,
          totalWaitingDebtor: result.waitingforusDebtor,
          totalCallsVip: result.callsVip,
          totalWaitingVip: result.waitingforusVip,
          totalWaitingCreditorVip: result.waitingforusCreditorVip,
          totalWaitingDebtorVip: result.waitingforusDebtorVip,
        });
        setUserTotals(newTotals);
      } catch (e) {
        console.log(e);
      }
    }
    setAssignedWaitingClaims(assignedClaims);
    setIsLoading(false);
  };

  useEffect(() => {
    if (loadedUsers && loadedUsers.length && tabValue === 0) {
      fetchUserTotals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedUsers, maximumDate, tabValue]);

  const [claimTotals, setClaimTotals] = useState<any>({});
  useEffect(() => {
    const authHeader = {
      headers: {
        Authorization: localStorage.getItem("credentials") || "",
        "Content-Type": "application/json",
      },
    };

    setIsLoading(true);
    fetch(process.env.REACT_APP_SERVER_URL + "/api/claims/aggregate", {
      method: "GET",
      ...authHeader,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          setClaimTotals(responseJson);
        }
        setIsLoading(false);
      });
  }, [tabValue]);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        className="notranslate"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const [showDetailedList, setShowDetailedList] = useState(false);

  return (
    <>
      <Title title="Operation Overview" />
      <SimpleForm toolbar={false} onSubmit={() => undefined}>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} lg={6}>
            <div
              className="filters"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              {mode === "day" && (
                <SelectInput
                  source="paymentDateDate"
                  alwaysOn
                  label="Day"
                  value={currentDate}
                  defaultValue={currentDate}
                  onChange={(e) => {
                    setCurrentDate(e.target.value as unknown as number);
                  }}
                  choices={[
                    { label: "(none)", value: 0 },
                    ...getDaysInMonth(currentMonth),
                  ]}
                  optionText="label"
                  optionValue="value"
                />
              )}
              <SelectInput
                source="paymentDateMonths"
                alwaysOn
                label="Month"
                value={currentMonth}
                defaultValue={currentMonth}
                onChange={(e) => {
                  setCurrentMonth(e.target.value as unknown as number);
                }}
                choices={availableMonths}
                optionText="label"
                optionValue="value"
              />
              <SelectInput
                source="paymentDateYear"
                alwaysOn
                label="Year"
                value={currentYear}
                defaultValue={currentYear}
                onChange={(e) =>
                  setCurrentYear(e.target.value as unknown as number)
                }
                choices={[
                  {
                    label: actualCurrentYear.toString(),
                    value: actualCurrentYear,
                  },
                  {
                    label: (actualCurrentYear - 1).toString(),
                    value: new Date(actualCurrentYear - 1, 1, 1).getFullYear(),
                  },
                  {
                    label: (actualCurrentYear - 2).toString(),
                    value: new Date(actualCurrentYear - 2, 1, 1).getFullYear(),
                  },
                  {
                    label: (actualCurrentYear - 3).toString(),
                    value: new Date(actualCurrentYear - 3, 1, 1).getFullYear(),
                  },
                  {
                    label: (actualCurrentYear - 4).toString(),
                    value: new Date(actualCurrentYear - 4, 1, 1).getFullYear(),
                  },
                ]}
                optionText="label"
                optionValue="value"
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 10,
              }}
            >
              <Button
                variant="text"
                size="medium"
                onClick={() => {
                  setMode(mode === "day" ? "month" : "day");
                }}
                label={"Toggle mode: " + mode}
              />
              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  jsonExport(
                    {
                      date:
                        new Date().getDate() +
                        "." +
                        currentMonth +
                        "." +
                        currentYear,
                      dueClaimsTotal: dueClaimsTotal,
                      activeClaims: activeClaims,
                      activeClaimsPaid: activeClaimsCompletelyPaid,
                      claimsWaitingForCreditorTotal:
                        claimsWaitingForCreditorTotal,
                      mailsTotal: mailsTotal,
                      mailsManualTotal: mailsManualTotal,
                      phoneCallsPlannedTotal: phoneCallsPlannedTotal,
                      phoneCallsTotal: phoneCallsTotal,
                      phoneCallsNotReachedTotal: phoneCallsNotReachedTotal,
                      lettersTotal: lettersTotal,
                    },
                    { rowDelimiter: ";" },
                    (err, csv) => {
                      // fix encoding
                      const BOM = "\uFEFF";
                      downloadCSV(
                        `${BOM} ${csv}`,
                        "Claims" + new Date().toISOString()
                      );
                    }
                  );
                }}
                label="Download Export"
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <SelectInput
              source="manager"
              alwaysOn
              label="Manager"
              value={currentManager}
              defaultValue={currentManager}
              onChange={(e) => {
                setCurrentManager(e.target.value);
              }}
              choices={[
                ...[{ label: "(none)", value: null }],
                ...loadedUsers.map((user) => ({
                  label: user.contactName,
                  value: user.id,
                })),
              ]}
              optionText="label"
              optionValue="value"
            />
          </Grid>
        </Grid>
      </SimpleForm>
      <Typography variant="h4">
        Operation Overview {isLoading && <CircularProgress size={24} />}{" "}
        {activitiesTotal > 0 && "(" + activitiesTotal + " done activities)"}
      </Typography>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={combinedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend onClick={toggleChartItem} />
          <Bar
            hide={!barVisibility.mailManualAmount}
            dataKey="mailManualAmount"
            name={mailsManualTotal + " Mails"}
            stackId="a"
            fill="#59b37b"
          />
          <Bar
            hide={!barVisibility.phoneCallsAmount}
            dataKey="phoneCallsAmount"
            name={phoneCallsTotal + " Calls"}
            stackId="a"
            fill="#8884d8"
          />
          <Bar
            hide={!barVisibility.phoneCallsNotReachedAmount}
            dataKey="phoneCallsNotReachedAmount"
            name={phoneCallsNotReachedTotal + " Unreached Calls"}
            stackId="a"
            fill="#ae84d8"
          />
          <Bar
            hide={!barVisibility.plannedPhoneCallsAmount}
            dataKey="plannedPhoneCallsAmount"
            name={phoneCallsPlannedTotal + " Calls planned"}
            stackId="a"
            fill="#b1aee8"
          />
          <Bar
            hide={!barVisibility.otherAmount}
            dataKey="otherAmount"
            name={othersTotal + " Other"}
            stackId="a"
            fill="#e3a020"
          />
          <Bar
            hide={!barVisibility.plannedOtherAmount}
            dataKey="plannedOtherAmount"
            name={plannedOthersTotal + " Other planned"}
            stackId="a"
            fill="#fac664"
          />
          <Bar
            hide={!barVisibility.researchsAmount}
            dataKey="researchsAmount"
            name={researchsTotal + " Research"}
            stackId="a"
            fill="#c32ac5"
          />
          <Bar
            hide={!barVisibility.internalFeedbackAmount}
            dataKey="internalFeedbackAmount"
            name={internalFeedbackTotal + " Internal Feedback"}
            stackId="a"
            fill="#2abfc5"
          />
          <Bar
            hide={!barVisibility.creditorInformation}
            dataKey="creditorInformationAmount"
            name={creditorInformationTotal + " Creditor Information"}
            stackId="a"
            fill="#0c7d81"
          />
          <Bar
            hide={!barVisibility.claimAuditsAmount}
            dataKey="claimAuditsAmount"
            name={
              Object.keys(claimAudits).length +
              " Updated Claims (" +
              claimAuditsTotal +
              " updates)"
            }
            stackId="a"
            fill="#20a4f3"
          />
        </BarChart>
      </ResponsiveContainer>
      {((mailsManual && mailsManualTotal > 0) ||
        (phoneCalls && phoneCallsTotal > 0)) && (
        <>
          <br />
          {phoneCalls && phoneCallsTotal > 0 && (
            <Typography variant="body2" align="center">
              Median time between calls:{" "}
              <b>
                {medianActivityDateDifferenceInMinutes(
                  Object.values(phoneCalls)
                )}{" "}
                minutes
              </b>
            </Typography>
          )}
          {mailsManual && mailsManualTotal > 0 && (
            <Typography variant="body2" align="center">
              Median time between mails:{" "}
              <b>
                {medianActivityDateDifferenceInMinutes(
                  Object.values(mailsManual)
                )}{" "}
                minutes
              </b>
            </Typography>
          )}
          <br />
          <Button
            variant="outlined"
            size="medium"
            onClick={() => {
              setShowDetailedList(!showDetailedList);
            }}
            label="Toggle detailed view"
          />
        </>
      )}
      {showDetailedList && mailsManual && mailsManualTotal > 0 && (
        <>
          <br />
          <br />
          <ListContextProvider
            value={{
              data: keyBy(mailsManual, "id"),
              ids: Object.keys(mailsManual).map((key) => key),
              currentSort: { field: "id", order: "ASC" },
              basePath: "/activity",
              resource: "Activity",
              selectedIds: [],
              total: mailsManualTotal,
              page: 1,
              perPage: 100,
            }}
          >
            <Datagrid rowClick="show" optimized>
              <ActivityDateField />
              <ActivitySummaryField />
              <RichTextField
                label="Mails"
                source="comment"
                style={{
                  wordBreak: "break-word",
                  minWidth: 350,
                  display: "inline-block",
                  whiteSpace: "pre-wrap",
                }}
              />
            </Datagrid>
          </ListContextProvider>
          <br />
        </>
      )}
      {showDetailedList && phoneCalls && phoneCallsTotal > 0 && (
        <>
          <br />
          <br />
          <ListContextProvider
            value={{
              data: keyBy(phoneCalls, "id"),
              ids: Object.keys(phoneCalls).map((key) => key),
              currentSort: { field: "id", order: "ASC" },
              basePath: "/activity",
              resource: "Activity",
              selectedIds: [],
              total: phoneCallsTotal,
              page: 1,
              perPage: 100,
            }}
          >
            <Datagrid rowClick="show" optimized>
              <ActivityDateField />
              <ActivitySummaryField />
              <RichTextField
                label="Calls"
                source="comment"
                style={{
                  wordBreak: "break-word",
                  minWidth: 350,
                  display: "inline-block",
                  whiteSpace: "pre-wrap",
                }}
              />
            </Datagrid>
          </ListContextProvider>
          <br />
        </>
      )}

      <AppBar position="static" style={{ marginTop: 20 }} color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="operations tabs"
        >
          <Tab
            label="To-dos"
            icon={<AssignmentIndOutlined />}
            {...a11yProps(0)}
          />
          <Tab
            icon={<TodayOutlined />}
            label="Current status"
            {...a11yProps(1)}
          />
          <Tab label="Traffic" icon={<TrafficOutlined />} {...a11yProps(2)} />
          <Tab
            label="Intensity"
            icon={<Brightness1Outlined />}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Claims in 2nd-4th dunning</Typography>
                <br />
                {activeClaims}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Paid Claims (last month)</Typography>
                Full: {activeClaimsCompletelyPaid}
                {activeClaimsCompletelyPaid !== undefined &&
                  activeClaims !== undefined &&
                  activeClaims !== 0 && (
                    <>
                      &nbsp;(
                      {parseFloat(
                        (
                          (activeClaimsCompletelyPaid / activeClaims) *
                          100
                        ).toFixed(2)
                      )}
                      %)
                    </>
                  )}
                {currentManager && (
                  <>
                    &nbsp;By current manager:{" "}
                    {activeClaimsCompletelyPaidByManager}
                  </>
                )}
                <br />
                Partly: {activeClaimsPartlyPaid}
                {activeClaimsPartlyPaid !== undefined &&
                  activeClaims !== undefined &&
                  activeClaims !== 0 && (
                    <>
                      &nbsp;(
                      {parseFloat(
                        ((activeClaimsPartlyPaid / activeClaims) * 100).toFixed(
                          2
                        )
                      )}
                      %)
                    </>
                  )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Waiting for creditor</Typography>
                <br />
                {claimsWaitingForCreditorTotal}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Claims in Payment Reminder</Typography>
                {claimTotals?.reminderLevel?._count}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Volume in Payment Reminder</Typography>
                {numberFormat.format(
                  claimTotals?.reminderLevel?._sum?.totalPending || 0
                )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  New Claims in Payment Reminder
                </Typography>
                {claimTotals?.newInReminderLevel?._count}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  Claims in Encashment & Open
                </Typography>
                {(claimTotals?.encashmentLevel?._count || 0) +
                  (claimTotals?.open?._count || 0)}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  Volume in Encashment & Open
                </Typography>
                {numberFormat.format(
                  (claimTotals?.encashmentLevel?._sum?.totalPending || 0) +
                    (claimTotals?.open?._sum?.totalPending || 0)
                )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  New Claims in Encashment & Open
                </Typography>
                {claimTotals?.newInEncashmentLevel?._count}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Claims in Judicial</Typography>
                {claimTotals?.judicialLevel?._count}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Volume in Judicial</Typography>
                {numberFormat.format(
                  claimTotals?.judicialLevel?._sum?.totalPending || 0
                )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={4} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  New Claims in Judicial (new)
                </Typography>
                {claimTotals?.newInJudicialLevel?._count}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  <Email fontSize="small" /> Mails sent
                </Typography>
                {mailsTotal}
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Replies received</Typography>
                {responsesTotal}
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Follow-ups sent</Typography>
                {mailsManualTotal}
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item></Grid>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  <Phone fontSize="small" /> Calls planned
                </Typography>
                {phoneCallsPlannedTotal}
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Calls done</Typography>
                {phoneCallsTotal}
              </Box>
            </Card>
          </Grid>

          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">Calls not reached</Typography>
                {phoneCallsNotReachedTotal}
                {phoneCallsNotReachedTotal !== undefined &&
                phoneCallsTotal !== undefined &&
                phoneCallsNotReachedTotal !== 0 &&
                phoneCallsTotal !== 0 ? (
                  <>
                    &nbsp;(
                    {parseFloat(
                      (
                        (phoneCallsNotReachedTotal / phoneCallsTotal) *
                        100
                      ).toFixed(2)
                    )}
                    %)
                  </>
                ) : (
                  " (0%)"
                )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item></Grid>
          <Grid xs={3} item>
            <Card>
              <Box padding={2}>
                <Typography variant="h6">
                  <MarkunreadMailboxOutlined fontSize="small" /> Letters sent
                </Typography>
                {lettersTotal} (automated)
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item></Grid>
          <Grid xs={3} item></Grid>
          <Grid xs={3} item></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            {mailsTotal !== undefined &&
              activeClaims !== undefined &&
              mailsManualTotal !== undefined && (
                <Card>
                  <Box padding={2}>
                    <Typography variant="h6">Mails per claim</Typography>
                    {"⌀ "}
                    {parseFloat((mailsTotal / activeClaims).toFixed(2))} Total.
                    {" ⌀ " +
                      parseFloat(
                        (mailsManualTotal / activeClaims).toFixed(2)
                      )}{" "}
                    Manual.
                  </Box>
                </Card>
              )}
          </Grid>
          <Grid xs={3} item>
            {phoneCallsTotal !== undefined && activeClaims !== undefined && (
              <Card>
                <Box padding={2}>
                  <Typography variant="h6">Calls per claim</Typography>⌀{" "}
                  {parseFloat((phoneCallsTotal / activeClaims).toFixed(2))}
                </Box>
              </Card>
            )}
          </Grid>
          <Grid xs={3} item>
            {lettersTotal !== undefined && activeClaims !== undefined && (
              <Card>
                <Box padding={2}>
                  <Typography variant="h6">Letters per claim</Typography>⌀{" "}
                  {parseFloat((lettersTotal / activeClaims).toFixed(2))}
                </Box>
              </Card>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      {tabValue === 0 && ( // for some reason does not allow "edit" when used in a tab panel - weeeeird
        <div style={{ minHeight: 500, paddingTop: 25 }}>
          {isLoading && <CircularProgress size={24} />}
          {userTotals.map((userTotal) => {
            return (
              <UserTile
                key={userTotal.user.id}
                userTotal={userTotal}
                refresh={refresh}
                openUsers={openUsers}
                onSave={() => onSaveUser(userTotal.user.id)}
              />
            );
          })}

          {totalWaitingClaims
            .filter(
              (item1) =>
                !assignedWaitingClaims.some((item2) => item2.id === item1.id)
            )
            .map((claim) => {
              return (
                <div key={claim.id}>
                  <a
                    href={`/#/Claim/${claim.id}/show`}
                    style={{ textDecoration: "none" }}
                  >
                    {claim.reference}
                  </a>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default OperationsOverview;

const UserTile = (props: {
  userTotal: any;
  openUsers: string[];
  refresh: (hard?: boolean) => void;
  onSave: () => void;
}) => {
  const [showEdit, setShowEdit] = useState(
    props.openUsers.filter((user) => !user.startsWith("total")).length > 0
  );

  return (
    <>
      {props.userTotal.user?.id === "total" && (
        <>
          <Divider />
          <br />
        </>
      )}

      <Grid container spacing={4} style={{ marginBottom: 5 }}>
        <Grid xs={4} item>
          {props.userTotal.user?.id &&
          !props.userTotal.user?.id?.startsWith("total") ? (
            <Card>
              <Box
                padding={2}
                style={{
                  display: "flex",
                  flexDirection: showEdit ? "column" : "row",
                  alignItems: showEdit ? "stretch" : "center",
                }}
              >
                <Typography
                  variant="body2"
                  style={{ width: "100%", paddingRight: "1rem" }}
                >
                  {props.userTotal.user?.contactName + " "}
                </Typography>
                {showEdit ? (
                  <>
                    <Button
                      variant="text"
                      size={"small"}
                      onClick={() => {
                        setShowEdit(false);
                      }}
                      label={"Close"}
                    />
                    <div style={{ marginTop: -10 }}>
                      <UserEdit
                        basePath="/User"
                        resource="User"
                        id={props.userTotal.user?.id}
                        options={{
                          inline: true,
                          onlyShowEmployeePreferences: true,
                          onSuccess: () => {
                            props.onSave();
                          },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Button
                    variant="text"
                    size={"medium"}
                    onClick={() => {
                      setShowEdit(true);
                    }}
                    label={"Edit"}
                  />
                )}
              </Box>
            </Card>
          ) : (
            <Box
              padding={2}
              alignItems={"flex-start"}
              flexDirection={"column-reverse"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: props.userTotal.user?.id?.startsWith("total")
                    ? "bold"
                    : "normal",
                }}
              >
                {props.userTotal.user?.contactName}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid xs={2} item>
          <Card>
            <Box
              padding={2}
              alignItems={"center"}
              flexDirection={"column-reverse"}
              justifyContent={"flex-star"}
              display={"flex"}
            >
              <Typography variant="body2">
                {props.userTotal.totalCalls}
              </Typography>
              Calls
            </Box>
          </Card>
        </Grid>
        <Grid xs={2} item>
          <Card>
            <Box
              padding={2}
              alignItems={"center"}
              flexDirection={"column-reverse"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Typography variant="body2">
                {props.userTotal.totalWaiting}
                {!!props.userTotal.totalWaitingVip
                  ? ` (${props.userTotal.totalWaitingVip} VIP)`
                  : ""}
              </Typography>
              Waiting
            </Box>
          </Card>
        </Grid>
        <Grid xs={2} item>
          <Card>
            <Box
              padding={2}
              alignItems={"center"}
              flexDirection={"column-reverse"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Typography variant="body2">
                {props.userTotal.totalWaitingCreditor}
                {!!props.userTotal.totalWaitingCreditorVip
                  ? ` (${props.userTotal.totalWaitingCreditorVip} VIP)`
                  : ""}
              </Typography>
              Waiting (Creditor)
            </Box>
          </Card>
        </Grid>
        <Grid xs={2} item>
          <Card>
            <Box
              padding={2}
              alignItems={"center"}
              flexDirection={"column-reverse"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Typography variant="body2">
                {props.userTotal.totalWaitingDebtor}
                {!!props.userTotal.totalWaitingDebtorVip
                  ? ` (${props.userTotal.totalWaitingDebtorVip} VIP)`
                  : ""}
              </Typography>
              Waiting (Debtor)
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
