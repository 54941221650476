import { Grid } from "@material-ui/core";
import qs from "qs";
import * as React from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { UserTitle } from "../user/UserTitle";

export const PaymentInformationCreate = (
  props: CreateProps
): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const notify = useNotify();

  return (
    <Create
      {...props}
      onSuccess={() => {
        notify("Success", "info", null, true);
        // previous pages: addressCreate -> userCreate
        if (parsedLocation.userId) {
          history.push("/User/" + parsedLocation.userId + "/show");
        } else {
          history.go(-1);
        }
      }}
    >
      <SimpleForm>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Account / IBAN"
              source="account"
              parse={(value: string) =>
                value
                  .toUpperCase()
                  .replace(/[^\dA-Z]/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim()
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Bank Identifier / SWIFT (only for international)"
              source="bankIdentifier"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="method"
              label="Method"
              defaultValue={"DebitAccount"}
              choices={[
                { label: "DebitAccount", value: "DebitAccount" },
                { label: "PayPal", value: "PayPal" },
              ]}
              optionText="label"
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <ReferenceInput
              defaultValue={parsedLocation.userId}
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
              })}
              disabled={!parsedLocation.userId}
              source="user.id"
              reference="User"
              label="User"
            >
              <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="currency"
              label="Currency (optional)"
              choices={[
                { label: "EUR", value: "EUR" },
                { label: "USD", value: "USD" },
                { label: "GBP", value: "GBP" },
                { label: "CHF", value: "CHF" },
                { label: "PLN", value: "PLN" },
                { label: "DKK", value: "DKK" },
                { label: "NOK", value: "NOK" },
                { label: "SEK", value: "SEK" },
                { label: "YEN", value: "YEN" },
                { label: "AUD", value: "AUD" },
                { label: "CAD", value: "CAD" },
                { label: "MXN", value: "SEK" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
