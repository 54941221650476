export enum EnumAddressCountry {
  DEU = "DEU",
  AUT = "AUT",
  CHE = "CHE",
  CHN = "CHN",
  ITA = "ITA",
  NLD = "NLD",
  NZL = "NZL",
  POL = "POL",
  EST = "EST",
  FRA = "FRA",
  ESP = "ESP",
  BEL = "BEL",
  GBR = "GBR",
  LUX = "LUX",
  ISL = "ISL",
  IRL = "IRL",
  TUR = "TUR",
  IND = "IND",
  MEX = "MEX",
  ISR = "ISR",
  BRA = "BRA",
  GRC = "GRC",
  TUN = "TUN",
  USA = "USA",
  DZA = "DZA",
  PRT = "PRT",
  MAR = "MAR",
  BGR = "BGR",
  BGD = "BGD",
  BLR = "BLR",
  OMN = "OMN",
  TWN = "TWN",
  DNK = "DNK",
  DOM = "DOM",
  CAN = "CAN",
  AUS = "AUS",
  ZAF = "ZAF",
  SRB = "SRB",
  ROU = "ROU",
  NGA = "NGA",
  EGY = "EGY",
  UGA = "UGA",
  SAU = "SAU",
  HUN = "HUN",
  HKG = "HKG",
  CYM = "CYM",
  SGP = "SGP",
  CHL = "CHL",
  KOR = "KOR",
  RUS = "RUS",
  CZE = "CZE",
  UKR = "UKR",
  LVA = "LVA",
  ARE = "ARE",
  SWE = "SWE",
  ALB = "ALB",
  BIH = "BIH",
  XKX = "XKX",
  HRV = "HRV",
  MNE = "MNE",
  MKD = "MKD",
  SVN = "SVN",
  SVK = "SVK",
  IDN = "IDN",
  NOR = "NOR",
  FIN = "FIN",
  MLT = "MLT",
  PHL = "PHL",
  MYS = "MYS",
  VNM = "VNM",
  GHA = "GHA",
  THA = "THA",
  PAK = "PAK",
  ZWE = "ZWE",
  KEN = "KEN",
  ARG = "ARG",
  PER = "PER",
  COL = "COL",
  KWT = "KWT",
  HND = "HND",
  LTU = "LTU",
  CYP = "CYP",
  QAT = "QAT",
  JPN = "JPN",
  JOR = "JOR",
  SEN = "SEN",
  LBN = "LBN",
  IRQ = "IRQ",
  SYR = "SYR",
  AFG = "AFG",
  YEM = "YEM",
  IRN = "IRN",
  ARM = "ARM",
  AZE = "AZE",
  GEO = "GEO",
  KGZ = "KGZ",
  KAZ = "KAZ",
  TKM = "TKM",
  UZB = "UZB",
  TJK = "TJK",
  ERI = "ERI",
  RUA = "RUA",
  BEN = "BEN",
  CMR = "CMR",
  GIN = "GIN",
  TGO = "TGO",
  CIV = "CIV",
  BFA = "BFA",
  BDI = "BDI",
  CPV = "CPV",
  CAF = "CAF",
  COM = "COM",
  COD = "COD",
  DJI = "DJI",
  GNQ = "GNQ",
  SWZ = "SWZ",
  ETH = "ETH",
  GAB = "GAB",
  GMB = "GMB",
  LSO = "LSO",
  LBR = "LBR",
  MDG = "MDG",
  MWI = "MWI",
  MLI = "MLI",
  MOZ = "MOZ",
  NAM = "NAM",
  NER = "NER",
  RWA = "RWA",
  STP = "STP",
  SYC = "SYC",
  SLE = "SLE",
  SOM = "SOM",
  SSD = "SSD",
  SDN = "SDN",
  TZA = "TZA",
  ZMB = "ZMB",
  JAM = "JAM",
  VEN = "VEN",
  URY = "URY",
  AGO = "AGO",
  GTM = "GTM",
  SLV = "SLV",
  BOL = "BOL",
  CRI = "CRI",
  CUB = "CUB",
  ECU = "ECU",
  HTI = "HTI",
  NIC = "NIC",
  PAN = "PAN",
  PRY = "PRY",
  GLP = "GLP",
  BHR = "BHR",
  MNG = "MNG",
  BMU = "BMU",
  JEY = "JEY",
  VCT = "VCT",
  KHM = "KHM",
  LIE = "LIE",
  LKA = "LKA",
  MDA = "MDA",
}

export const GERMAN_SPEAKING_COUNTRIES = [
  EnumAddressCountry.DEU,
  EnumAddressCountry.AUT,
  EnumAddressCountry.CHE,
];

export const FRENCH_SPEAKING_COUNTRIES = [EnumAddressCountry.FRA];
